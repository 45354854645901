import React, { Component } from 'react';

export class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { play: false };
        this.url = "https://ooami-streams.herokuapp.com/podcast/5c8009a3493796001638062e";
        this.audio = new Audio(this.url);
        this.togglePlay = this.togglePlay.bind(this);
        // this.state.play = true;
    }
    togglePlay() {
        this.setState({ play: !this.state.play });
        console.log(this.audio);
        this.state.play ? this.audio.play() : this.audio.pause();
    }
    changeThis() {
        console.log("Ended");
    }
    canPlay() {
        // console.log("can play");
        var audio = document.getElementById("testings");
        audio.play();
    }
    render() {
        return <div>
            {/* <button onClick={this.togglePlay}>{this.state.play ? 'Pause' : 'Play'}</button> */}
            <audio ref="testings" src="https://ooami-streams.herokuapp.com/podcast/5c8009a3493796001638062e" controls autoPlay/>
        </div>
    }
}

